import "utils/wdyr";
import "tailwindcss/tailwind.css";
import "global.css";
import "polyfills";
import type { AppProps } from "next/app";
import Amplify from "aws-amplify";
import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import Login from "layouts/Login";
import { AuthProvider, useAuth } from "contexts/auth";
import awsconfig from "aws-exports";
import ErrorFallback from "components/ErrorFallback";
import { startServiceWorker } from "utils/sw";
import { AlertsProvider, useAlerts } from "contexts/alerts";
import { useNetworkStatus } from "hooks/online";
import { DialogProvider } from "contexts/dialog";
import { TenantProvider } from "contexts/tenant";
import { UsersProvider } from "contexts/users";

import "moment/locale/ja";

moment.locale("ja");

Amplify.configure(awsconfig);

function PaxAppContent({ Component, pageProps }: AppProps) {
  const { user, loadingUser } = useAuth();
  const { addAlert } = useAlerts();
  const online = useNetworkStatus();
  const [prevOnlineStatus, setPrevOnlineStatus] = useState(online);

  useEffect(() => {
    startServiceWorker();
  }, []);

  useEffect(() => {
    if (online !== prevOnlineStatus) {
      if (online) {
        addAlert({
          message: "インターネット接続が復帰しました",
          severity: "info",
        });
      } else {
        addAlert({
          message: "インターネットに接続されていません",
          severity: "error",
        });
      }
      setPrevOnlineStatus(online);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online]);

  if (loadingUser) return <LinearProgress color="primary" />;

  if (user === undefined && !loadingUser) return <Login />;

  return <Component {...pageProps} />;
}

function PaxApp(appProps: AppProps) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AlertsProvider>
        <AuthProvider>
          <DialogProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <PaxAppContent {...appProps} />
            </LocalizationProvider>
          </DialogProvider>
        </AuthProvider>
      </AlertsProvider>
    </ErrorBoundary>
  );
}

export default PaxApp;
